import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Space, Select } from 'antd';
import { FilterType } from '../types/Admin';

const { Option } = Select;

type PropTypes = {
  filter: FilterType;
  setFilterObject: Function;
  removeFilter: Function;
  loading: boolean;
};

export default function TableSecondaryFilterComponent({
  filter,
  setFilterObject,
  removeFilter,
  loading,
}: PropTypes) {
  const [chosen, setChosen] = useState<any[]>([]);

  useEffect(() => {
    if (chosen.length > 0) setFilterObject(chosen);
  }, [chosen]);

  return (
    <Row>
      <Col span={24}>
        <Space>
          <Button onClick={() => removeFilter(filter)}>X</Button>
          <span>{filter.key}</span>
        </Space>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          options={filter.items}
          allowClear
          disabled={loading}
          placeholder="Select your filter"
          onChange={(strings, selected) => {
            // @ts-ignore
            setChosen(() => [...selected]);
          }}
        >
          {filter.items.map((fi) => (
            <Option value={fi.id}>{fi.value}</Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}
