import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Space, Table, Input, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useTime } from '../../hooks/useTime';
import AlertsServices from '../../services/alerts';
import TimeFilterComponent from '../../components/TimeFilter';
import TableFilterComponent from '../../components/TableFilter';
import { FiltersType } from '../../types/Admin';
import useDebounce from '../../hooks/useDebounce';

const { Title } = Typography;

export default function AlertsView() {
  const TimeContext = useTime();
  const { selectedTimeFilter, changeFlag } = TimeContext;
  const [allAlerts, setAlerts] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filterOptions, setFilterOptions] = useState<FiltersType['filters']>(
    []
  );
  const [filterObject, setFilterObject] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);

  const copyToClipboard = async (value) => {
    if (typeof value === 'string') {
      await window.navigator.clipboard.writeText(value);
      message.info('Data copied to clipboard!');
    }
  };

  const columns = [
    {
      title: 'event ID',
      dataIndex: 'alert_id',
      key: 'alert_id',
    },
    {
      title: 'Attack Type',
      dataIndex: 'alert_type_name',
      key: 'alert_type_name',
    },
    {
      title: 'SID',
      dataIndex: 'sid',
      key: 'sid',
    },
    {
      title: 'Timestamp',
      dataIndex: 'receive_ts',
      key: 'receive_ts',
      render: (time) => new Date(time).toLocaleString(),
    },
    {
      title: 'Origin Domain',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Full URL',
      dataIndex: 'url',
      key: 'url',
      render: (fullUrl) => (
        <>
          <CopyOutlined
            style={{ color: 'var(--primary-blue)' }}
            onClick={() => copyToClipboard(fullUrl)}
          />
          &nbsp;
          {fullUrl}
        </>
      ),
    },
    {
      title: 'Tenant Name',
      dataIndex: 'tenant_name',
      key: 'tenant_name',
    },
  ];

  const GetAllAlerts = async () => {
    if (loading) return;
    const payload: any = {
      page: currentPage,
      start: selectedTimeFilter.start,
      end: selectedTimeFilter.end,
      per_page: perPage,
      search: debouncedSearchTerm,
      filter: {},
    };
    if (Object.keys(filterObject).length > 0) payload.filter = filterObject;
    else payload.filter = undefined;
    setLoading(true);
    try {
      const res: any = await AlertsServices.GetAllAlerts(payload);
      const { data } = res;
      setAlerts(() => data.items);
      setTotalPages(() => data.total);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const GetAlertsFilters = async () => {
    try {
      const res: any = await AlertsServices.GetAlertsFilters();
      const { data } = res;
      setFilterOptions(() => data);
    } catch (e) {
      setFilterOptions(() => []);
    }
  };
  const handleTableChange = (pagination: any) => {
    if (currentPage !== pagination.current)
      setCurrentPage(() => pagination.current);
    if (pagination.pageSize !== perPage) setPerPage(() => pagination.pageSize);
  };

  useEffect(() => {
    GetAllAlerts();
  }, [changeFlag, currentPage, debouncedSearchTerm, filterObject, perPage]);

  useEffect(() => {
    GetAlertsFilters();
  }, []);

  return (
    <Row>
      <Title level={2}>Alerts</Title>
      <Col span={24}>
        <Space>
          <TimeFilterComponent />
          <Input
            type="text"
            placeholder="Search Alerts"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: '200px' }}
          />
        </Space>
      </Col>
      <Col span={24}>
        <TableFilterComponent
          filters={filterOptions}
          setFilter={(filter) => {
            setFilterObject(() => filter);
          }}
          loading={loading}
        />
      </Col>
      <Col span={24} className="alerts-grid">
        <Table
          loading={loading}
          rowKey="alert_id"
          columns={columns}
          dataSource={allAlerts}
          onChange={handleTableChange}
          pagination={{
            position: ['bottomCenter'],
            total: totalPages,
          }}
        />
      </Col>
    </Row>
  );
}
