import React, { createContext, useContext, useState } from 'react';

type SelectedTimeFilterType = {
  start: string;
  end: string;
};
interface TimeContextProps {
  selectedTimeFilter: SelectedTimeFilterType;
  selectTimeFilter: Function;
  timeOption: string;
  setTimeOption: Function;
  changeFlag: boolean;
  setChangeFlag: Function;
}
interface ChildrenProps {
  children: React.ReactNode;
}

const today = new Date();
const yesterday = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 1
);

const TimeContext = createContext<TimeContextProps>({
  selectedTimeFilter: {
    start: yesterday.toISOString(),
    end: today.toISOString(),
  } as SelectedTimeFilterType,
  selectTimeFilter: (): void => {},
  timeOption: 'day',
  setTimeOption: (): void => {},
  changeFlag: false,
  setChangeFlag: (): void => {},
});

const useProviderTime = () => {
  const [timeOption, setTimeOption] = useState<string>('day');
  const [changeFlag, setChangeFlag] = useState<boolean>(false);
  const [selectedTimeFilter, setTimeFilter] = useState<SelectedTimeFilterType>({
    start: yesterday.toISOString(),
    end: today.toISOString(),
  });
  const selectTimeFilter = (timeRange: SelectedTimeFilterType) =>
    new Promise((resolve) => {
      setTimeFilter(timeRange);
      resolve(timeRange);
    });
  return {
    selectedTimeFilter,
    selectTimeFilter,
    timeOption,
    setTimeOption,
    changeFlag,
    setChangeFlag,
  } as const;
};

export default function ProviderTime({ children }: ChildrenProps) {
  const timeContext = useProviderTime();
  return (
    <TimeContext.Provider value={timeContext}>{children}</TimeContext.Provider>
  );
}
export const useTime = () => useContext(TimeContext);
