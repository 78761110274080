import React, { useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Space, message } from 'antd';
import TenantServices from '../../services/tenants';

interface DeleteTenantProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  goodAccountName: string;
  tenantId: number;
}

export default function DeleteTenantModal({
  visible,
  onClose,
  onSuccess,
  goodAccountName,
  tenantId,
}: DeleteTenantProps) {
  const [form] = Form.useForm();
  const [accountName, setAccountName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setLoading(true);
    const payload = {
      account_name: values.account_name,
      tenantId,
    };
    TenantServices.deleteTenant(payload)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
      message.error('Please fill all required fields!');
    }
    setLoading(false);
  };

  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      title="Delete tenant confirmation"
      onCancel={() => {
        setLoading(false);
        onClose();
      }}
      footer={
        <Row justify="end">
          <Col>
            <Space>
              <Button onClick={onClose} disabled={loading}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading || accountName !== goodAccountName}
                loading={loading}
                onClick={() => {
                  form.submit();
                }}
              >
                Confirm
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <Form
        name="confirm-delete-tenant"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Account Name"
          name="account_name"
          rules={[{ required: true, message: 'Please input the account name' }]}
        >
          <Input onChange={(e) => setAccountName(e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
