import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

interface AllTenantsProps {
  items: {}[];
  pages?: number;
  currentPage?: number;
  total: number;
  // eslint-disable-next-line no-unused-vars
  onPageChange: (page: number, pageSize: number) => void;
  loading: boolean;
  region: string;
}

const AllTenants = (props: AllTenantsProps) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (data: any, record: any) => (
        <Link
          to={`/tenant/${record.id}/${props.region}`}
          style={{ color: record.marked_as_deleted ? 'red' : '' }}
        >
          {data}
        </Link>
      ),
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
    },
    {
      title: 'Contact email',
      dataIndex: 'contact_email',
      key: 'contact_email',
    },
    {
      title: 'Contact phone',
      dataIndex: 'contact_phone',
      key: 'contact_phone',
    },
    {
      title: 'Tenant ID',
      dataIndex: 'id',
      key: 'tenant_id',
    },
    // {
    //   title: 'Installation Token',
    //   dataIndex: 'ext_installation_token',
    //   key: 'ext_installation_token',
    // },
  ];

  const onTableChange = (pagination) => {
    props.onPageChange(pagination.current, pagination.pageSize);
  };

  return (
    <Table
      columns={columns}
      rowKey={(item: any) => item.id}
      dataSource={props.items}
      pagination={{
        total: props.total,
        current: props.currentPage,
        pageSize: 10,
        position: ['bottomCenter'],
      }}
      onChange={onTableChange}
      loading={props.loading}
      className="tenants-table"
    />
  );
};

export default AllTenants;
