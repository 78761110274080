import {
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import GetUserPool from './pool';
// import { useAuth } from '../hooks/useAuth';

export default {
  registerUser(payload: {
    accountName: string;
    email: string;
    password: string;
  }) {
    const userPool = GetUserPool();
    const { accountName, email, password } = payload;
    const attrs = [
      {
        Name: 'accountName',
        Value: accountName,
      },
      {
        Name: 'email',
        Value: email,
      },
    ];
    const attrEmail = new CognitoUserAttribute(attrs[1]);
    const attrName = new CognitoUserAttribute(attrs[0]);
    userPool.signUp(
      email,
      password,
      [attrEmail, attrName],
      [],
      (err, result) => {
        if (err) throw new Error(JSON.stringify(err));
        window.console.log(result?.user.getUsername());
      }
    );
  },
  loginUser(email: string, password: string) {
    const userPool = GetUserPool();
    const authData = {
      Username: email,
      Password: password,
      Pool: userPool,
    };
    const authDetails = new AuthenticationDetails(authData);
    const cognitoUser = new CognitoUser(authData);
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (res) => {
          const accessToken: string = res.getAccessToken().getJwtToken();
          const idToken: string = res.getIdToken().getJwtToken();
          const decoded = jwt_decode(accessToken);
          const decId = jwt_decode(idToken);
          window.console.log('User: ', decoded);
          window.console.log('decoded: ', decId);
          resolve({ idToken });
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: () => {
          cognitoUser.completeNewPasswordChallenge(password, [], {
            onSuccess: (session) => {
              resolve(session);
            },
            onFailure(err: any): void {
              reject(err);
            },
          });
        },
      });
    });
  },
  getUserFromStorage() {
    const userPool = GetUserPool();
    const cognitoUser = userPool.getCurrentUser();
    return new Promise((resolve, reject): void => {
      if (!cognitoUser) reject();
      else {
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err);
          }
          resolve({
            user: session,
            isValid: session.isValid(),
          });
        });
      }
      reject(Error('No user logged in.'));
    });
  },
  logoutUser() {
    const userPool = GetUserPool();
    const cognitoUser = userPool.getCurrentUser();
    return new Promise<void>((resolve) => {
      if (cognitoUser) cognitoUser?.signOut();
      resolve();
    });
  },
};
