import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';

import AllHostsComponent from '../components/AllHosts';
import HostsService from '../services/hosts';

const { Title } = Typography;

type PropsTypes = {
  items: {}[];
  pages?: number;
  currentPage?: number;
  total: number;
};

export default function HomeView() {
  const [loading, setLoading] = useState<boolean>(false);
  const [agents, setAgents] = useState<PropsTypes>({
    items: [],
    pages: 1,
    currentPage: 1,
    total: 0,
  });

  const GetHosts = (page: number, pageSize: number) => {
    setLoading(() => true);
    HostsService.getAllHosts(page, pageSize)
      .then(({ data }: any) => {
        setLoading(() => false);
        setAgents(() => ({
          items: data.items,
          pages: data.pages,
          currentPage: data.current,
          total: data.total,
        }));
      })
      .catch(() => {
        setLoading(false);
        setAgents({
          items: [],
          pages: 1,
          currentPage: 1,
          total: 0,
        });
      });
  };
  useEffect(() => {
    GetHosts(1, 10);
  }, []);

  return (
    <>
      <Row style={{ paddingBottom: '12px' }}>
        <Col span={24}>
          <Title level={2}>All endpoints</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AllHostsComponent
            items={agents.items}
            total={agents.total}
            currentPage={agents.currentPage}
            pages={agents.pages}
            loading={loading}
            onPageChange={(page, pageSize) => {
              GetHosts(page, pageSize);
            }}
          />
        </Col>
      </Row>
    </>
  );
}
