import Http from './http';
import { ALL_HOSTS } from './api';

export default {
  getAllHosts(page: number, pageSize: number) {
    return new Promise((resolve, reject) => {
      const samplePayload = {
        start: '2021-01-23 09:01:16.690449',
        end: '2022-09-25 09:01:16.690449',
        per_page: pageSize,
        page,
      };
      Http.post(ALL_HOSTS, samplePayload)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};
