import React from 'react';
import { Card, Col, Row, Space } from 'antd';

type PropTypes = {
  contact_email: string;
  contact_phone: string;
  created_at: string;
  account_name: string;
  cognito_pool_id: string;
  protected: boolean;
  loading: boolean;
};

export default function TenantDetails({ ...props }: PropTypes) {
  return (
    <Card title="Details" loading={props.loading}>
      {props.loading ? (
        ''
      ) : (
        <Row>
          <Col span={12}>
            <Card type="inner" bordered={false}>
              <Space direction="vertical">
                <p>
                  Contact Email:&nbsp;
                  <strong>{props.contact_email}</strong>
                </p>
                <p>
                  Contact Phone:&nbsp;
                  <strong>{props.contact_phone}</strong>
                </p>
                <p>
                  Created At:&nbsp;
                  <strong>
                    {props.created_at
                      ? new Date(props.created_at).toDateString()
                      : 'N/A'}
                  </strong>
                </p>
              </Space>
            </Card>
          </Col>
          <Col span={12}>
            <Card type="inner" bordered={false}>
              <Space direction="vertical">
                <p>
                  Account Name:&nbsp;
                  <strong>{props.account_name}</strong>
                </p>
                <p>
                  Cognito Pool ID:&nbsp;
                  <strong>{props.cognito_pool_id}</strong>
                </p>
                <p>
                  Protected:&nbsp;
                  <strong>{props.protected?.toString()}</strong>
                </p>
              </Space>
            </Card>
          </Col>
        </Row>
      )}
    </Card>
  );
}
