import React from 'react';
import { Table } from 'antd';

type PropsTypes = {
  items: {}[];
  pages?: number;
  currentPage?: number;
  total: number;
  // eslint-disable-next-line no-unused-vars
  onPageChange: (page: number, pageSize: number) => void;
  loading: boolean;
};

export default function AllHostsComponent(props: PropsTypes) {
  const columns = [
    {
      title: 'Host ID',
      dataIndex: 'host_id',
      key: 'host_id',
    },
    {
      title: 'Tenant Name',
      dataIndex: 'tenant_name',
      key: 'tenant_name',
    },
    {
      title: 'Last Seen',
      dataIndex: 'last_seen',
      key: 'last_seen',
      render: (text) => <span>{new Date(text).toDateString()}</span>,
    },
  ];

  const onTableChange = (pagination) => {
    props.onPageChange(pagination.current, pagination.pageSize);
  };

  return (
    <Table
      columns={columns}
      rowKey={(item: any) => item.host_id}
      dataSource={props.items}
      pagination={{
        total: props.total,
        current: props.currentPage,
        position: ['bottomCenter'],
        defaultPageSize: 10,
      }}
      onChange={onTableChange}
      loading={props.loading}
    />
  );
}
