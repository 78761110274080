import Http from './http';
import { ALL_ALERTS, ALERTS_FILTERS } from './api';

export default {
  GetAllAlerts(payload) {
    return new Promise((resolve, reject) => {
      Http.post(ALL_ALERTS, payload)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  GetAlertsFilters() {
    return new Promise((resolve, reject) => {
      Http.get(ALERTS_FILTERS)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};
