import React from 'react';
import 'antd/dist/antd.css';
import './App.scss';
import './assets/main.scss';
import { ProvideAuth } from './hooks/useAuth';
import ProviderTime from './hooks/useTime';
import MainRouter from './router';

function App() {
  return (
    <div className="App">
      <ProvideAuth>
        <ProviderTime>
          <MainRouter />
        </ProviderTime>
      </ProvideAuth>
    </div>
  );
}

export default App;
