import Http from './http';
import { RESET_PASSWORD } from './api';

const TENANTS_BASE_PATH = '/admin/tenant';

export default {
  getAll(page: number, pageSize: number, region: null | string) {
    return new Promise((resolve, reject) => {
      const samplePayload = {
        per_page: pageSize,
        page,
        region,
      };
      Http.post(`${TENANTS_BASE_PATH}/all`, samplePayload)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  addNewTenant(data) {
    return new Promise((resolve, reject) => {
      Http.post(`${TENANTS_BASE_PATH}/add-new-tenant`, data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  getTenantDetails(id, region) {
    return new Promise((resolve, reject) => {
      Http.get(`${TENANTS_BASE_PATH}/${id}?region=${region}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  getTenantAnalytics(id, region) {
    return new Promise((resolve, reject) => {
      Http.get(`${TENANTS_BASE_PATH}/${id}/analytics?region=${region}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  getTenantUsers(payload) {
    return new Promise((resolve, reject) => {
      Http.post('/admin/user', payload)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  deleteTenant(payload) {
    return new Promise((resolve, reject) => {
      Http.delete(`${TENANTS_BASE_PATH}/${payload.tenantId}`, {
        data: {
          account_name: payload.account_name,
        },
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  generateTenantToken(tenantId) {
    return new Promise((resolve, reject) => {
      Http.post(`${TENANTS_BASE_PATH}/${tenantId}/installation-token`, {})
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  setMfaStatus(tenantId, enabled) {
    return new Promise((resolve, reject) => {
      Http.post(`${TENANTS_BASE_PATH}/${tenantId}/mfa`, {
        enabled,
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  resetPasswordMfa(payload) {
    return new Promise((resolve, reject) => {
      Http.post(`${RESET_PASSWORD}`, payload)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};
