import { CognitoUserPool } from 'amazon-cognito-identity-js';
import PoolConfig from '../types/Pool';

// User pool name: browserdome-superadmin
// User pool id: us-east-1_fft1j149z
// Application client id: 3913mvug8oariiliis7497ldm7

const MainPool: PoolConfig = {
  UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID || 'us-east-1_fft1j149z',
  ClientId:
    process.env.REACT_APP_COGNITO_APP_ID || '3913mvug8oariiliis7497ldm7',
};

export default function GetUserPool() {
  return new CognitoUserPool(MainPool);
}
