import React from 'react';
import { Button, Image, Menu } from 'antd';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import Logo from '../assets/Logo_white.svg';

const menuItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Tenants',
    path: '/tenant',
  },
  {
    title: 'Alerts',
    path: '/alerts',
  },
];

export default function SidebarComponent() {
  const history = useHistory();
  const auth = useAuth();

  return (
    <div className="sidebar bg-primary">
      <div
        style={{
          textAlign: 'center',
          paddingTop: '12px',
          paddingBottom: '12px',
        }}
      >
        <Image src={Logo} />
      </div>
      <Menu
        theme="light"
        items={menuItems.map((item) => ({
          key: item.path,
          label: item.title,
          onClick: () => history.push(item.path),
        }))}
      />
      <div className="sidebar-footer">
        <Button onClick={() => auth.logout()}>Logout</Button>
      </div>
    </div>
  );
}
