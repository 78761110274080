import React, { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Space, Select } from 'antd';
import { useTime } from '../hooks/useTime';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function TimeFilterComponent() {
  const TimeContext = useTime();
  const {
    selectTimeFilter,
    setChangeFlag,
    changeFlag,
    timeOption,
    setTimeOption,
  } = TimeContext;
  const today = new Date();
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [timeFilter, setTimeFilter] = useState<string>('day');
  const pickTime = (startDate: Date | string, endDate: Date | string) =>
    selectTimeFilter({
      start: new Date(startDate).toISOString(),
      end: new Date(endDate).toISOString(),
    });
  const handleChange = (value, show = true) => {
    setTimeFilter(value);
    setTimeOption(value);
    setShowPicker(false);
    if (value !== 'custom') setChangeFlag(!changeFlag);
    switch (value) {
      case 'day':
        pickTime(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1),
          today
        );
        break;
      case 'week':
        pickTime(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
          today
        );
        break;
      case 'month':
        pickTime(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30),
          today
        );
        break;
      case 'quarter':
        pickTime(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 90),
          today
        );
        break;
      default:
        setShowPicker(show);
        break;
    }
  };

  useEffect(() => {
    if (timeOption !== 'custom') {
      handleChange(timeOption);
    } else {
      handleChange(TimeContext.timeOption, false);
    }
  }, []);

  return (
    <Row>
      <Col span={24}>
        Select a time period:
        <Select
          defaultValue={timeFilter}
          onChange={(val) => {
            setChangeFlag(!changeFlag);
            handleChange(val);
          }}
        >
          <Option value="day">Last Day</Option>
          <Option value="week">Last Week</Option>
          <Option value="month">Last Month</Option>
          <Option value="quarter">Last Quarter</Option>
          <Option value="custom">Custom</Option>
        </Select>
        {showPicker && (
          <Space>
            <RangePicker />
          </Space>
        )}
      </Col>
    </Row>
  );
}
