import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Table,
  Card,
  Button,
  notification,
  message,
  Row,
  Col,
  Space,
  Modal,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import TenantServices from '../../services/tenants';
import TenantDetailsComponent from './Details';
import TenantAnalytics from './Analytics';
import DeleteTenantModal from './DeleteTenantModal';

interface TenantDetails {
  name: string;
  account_name: string;
  created_at: string;
  protected_last_changed: string;
  protected: boolean;
  description: string;
  contact_email: string;
  contact_phone: string;
  cognito_pool_id: string;
  marked_as_deleted: boolean;
  ext_installation_token: string;
  mfa: boolean;
}
type AnalyticsType = {
  active_agents: number;
  installed_agents: number;
  admin_users: number;
  admin_last_active: string;
  installed_endpoints: number;
};

export default function TenantsDetailsView() {
  const { id, region } = useParams();
  const history = useHistory();
  const [tenantDetails, setTenantDetails] = useState<TenantDetails | null>({
    name: '',
    account_name: '',
    created_at: '',
    protected_last_changed: '',
    protected: false,
    description: '',
    contact_email: '',
    contact_phone: '',
    cognito_pool_id: '',
    marked_as_deleted: false,
    ext_installation_token: '',
    mfa: false,
  });
  const [tenantAnalytics, setTenantAnalytics] = useState<AnalyticsType>({
    active_agents: 0,
    installed_agents: 0,
    admin_users: 0,
    admin_last_active: '',
    installed_endpoints: 0,
  });
  const [users, setUsers] = useState<{}[]>([]);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [loadingTenant, setLoadingTenant] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingStats, setLoadingStats] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [newToken, setNewToken] = useState<boolean>(false);
  const [mfaing, setMfaing] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const cols = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Nr',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Action',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (data, record) => (
        <Button
          type="link"
          onClick={() => {
            setSelectedId(() => parseInt(record.user_id, 10));
            setShowModal(true);
          }}
        >
          Reset Password
        </Button>
      ),
    },
  ];

  const ModalComponent = () => (
    <Modal
      title="Confirm Reset Password"
      visible={showModal}
      onCancel={() => setShowModal(false)}
      onOk={() => {
        ResetUserPassword();
        setShowModal(false);
      }}
    >
      <p>
        Are you sure you want to reset the password for this User? (This will
        also reset the User&apos;s MFA settings and send a new random password
        to their email.)
      </p>
    </Modal>
  );
  const GetTenantDetails = () => {
    setLoadingTenant(true);
    TenantServices.getTenantDetails(id, region)
      .then(({ data }: any) => {
        setLoadingTenant(false);
        setTenantDetails(data);
      })
      .catch((err) => {
        setLoadingTenant(false);
        setTenantDetails(null);
        window.console.error(err);
      });
  };
  const GetTenantAnalytics = () => {
    setLoadingStats(true);
    TenantServices.getTenantAnalytics(id, region)
      .then(({ data }: any) => {
        setLoadingStats(false);
        setTenantAnalytics(data);
      })
      .catch((err) => {
        setLoadingStats(false);
        window.console.log('error occurred: ', err);
      });
  };
  const GetTenantUsers = () => {
    setLoadingUsers(true);
    const p = {
      search: '',
      tenant_id: id,
    };
    TenantServices.getTenantUsers(p)
      .then(({ data }: any) => {
        setLoadingUsers(false);
        setUsers(data);
      })
      .catch((err) => {
        setLoadingUsers(false);
        window.console.error(err);
      });
  };
  const onGenerateNewToken = () => {
    setNewToken(true);
    TenantServices.generateTenantToken(id)
      .then(async () => {
        setNewToken(false);
        await message.success('A new token was generated successfully!');
        await GetTenantDetails();
      })
      .catch(async (err) => {
        setNewToken(false);
        await message.error(
          err.response.data.message ||
            'An error occurred! Please try again later'
        );
      });
  };
  const SetMfaStatus = () => {
    const wasActive = tenantDetails?.mfa;
    setMfaing(true);
    TenantServices.setMfaStatus(id, !wasActive)
      .then(async () => {
        setMfaing(false);
        if (wasActive)
          await message.success(
            'You successfully disabled MFA for your Users!'
          );
        else
          await message.success('You successfully enabled MFA for your Users!');
        setTimeout(() => {
          GetTenantDetails();
        }, 500);
      })
      .catch(async (err) => {
        setMfaing(false);
        const { data } = err.response;
        const msg = data.message;
        await message.error(
          msg ||
            'Oops, something wrong happened while setting your new MFA status! Please try again later.'
        );
      });
  };
  const ResetUserPassword = () => {
    setLoadingUsers(true);
    const payload = {
      id: selectedId,
      tenant_id: parseInt(id, 10),
    };
    TenantServices.resetPasswordMfa(payload)
      .then(async () => {
        setLoadingUsers(false);
        await message.success(
          'The password for the User was reset successfully.'
        );
      })
      .catch(async (err) => {
        setLoadingUsers(false);
        const { data } = err.response;
        await message.error(
          data?.message ||
            'Could not reset the password for this User at this time.'
        );
      });
  };

  const copyToClipboard = async (value) => {
    if (typeof value === 'string') {
      await navigator.clipboard.writeText(value);
      message.info('Token copied to clipboard!');
    }
  };

  useEffect(() => {
    GetTenantDetails();
    GetTenantAnalytics();
    GetTenantUsers();
  }, []);

  return (
    <div className="site-page-header-ghost-wrapper">
      {ModalComponent()}
      <PageHeader
        ghost={false}
        title={tenantDetails?.name}
        onBack={() => window.history.back()}
        subTitle={tenantDetails?.description}
      >
        <Row>
          <Col span={12}>
            <Space>
              <span className={tenantDetails?.mfa ? 'success' : 'danger'}>
                {tenantDetails?.mfa
                  ? 'MFA authentication is enabled for your users.'
                  : 'MFA authentication is disabled for your users.'}
              </span>
              <Button
                className="primary"
                disabled={mfaing}
                loading={mfaing}
                onClick={SetMfaStatus}
              >
                {tenantDetails?.mfa ? 'Disable' : 'Enable'}
              </Button>
            </Space>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Space>
              <Button
                className="primary"
                disabled={newToken}
                onClick={() => onGenerateNewToken()}
              >
                {newToken ? 'Generating new token...' : 'Generate New Token'}
              </Button>
              {!tenantDetails?.marked_as_deleted && (
                <>
                  <Button
                    className="danger"
                    onClick={() => setModalVisible(true)}
                  >
                    Delete Tenant
                  </Button>
                  <DeleteTenantModal
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    onSuccess={() => {
                      setModalVisible(false);
                      notification.open({
                        message: 'Tenant Was Deleted Successfully!',
                      });
                      setTimeout(() => {
                        history.goBack();
                      }, 2000);
                    }}
                    goodAccountName={tenantDetails?.account_name || ''}
                    tenantId={id}
                  />
                </>
              )}
            </Space>
          </Col>
        </Row>
      </PageHeader>
      <div className="site-card-wrapper">
        {tenantDetails && (
          <TenantDetailsComponent
            loading={loadingTenant}
            cognito_pool_id={tenantDetails.cognito_pool_id}
            account_name={tenantDetails?.account_name}
            contact_email={tenantDetails.contact_email}
            contact_phone={tenantDetails.contact_phone}
            created_at={tenantDetails.created_at}
            protected={tenantDetails.protected}
          />
        )}
      </div>
      <Row>
        <Col span={24}>
          {tenantAnalytics && (
            <TenantAnalytics
              loading={loadingStats}
              active_agents={tenantAnalytics.active_agents}
              admin_last_active={tenantAnalytics.admin_last_active}
              admin_users={tenantAnalytics.admin_users}
              installed_agents={tenantAnalytics.installed_agents}
              installed_endpoints={tenantAnalytics.installed_endpoints}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card title="Installation Token">
            <CopyOutlined
              style={{ color: 'var(--primary-blue)' }}
              onClick={() =>
                copyToClipboard(tenantDetails?.ext_installation_token)
              }
            />
            &nbsp;
            {tenantDetails?.ext_installation_token}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card title="Users">
            <Table
              loading={loadingUsers}
              dataSource={users}
              columns={cols}
              pagination={{ position: ['bottomCenter'] }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
