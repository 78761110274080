import React, { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { ProviderProps, User } from '../types/User';
import AuthService from '../services/auth';

interface ChildrenProps {
  children: React.ReactNode;
}

const authContext = createContext<ProviderProps>({
  user: {
    accountName: '',
    email: '',
  },
  login: (): void => {},
  register: (): void => {},
  logout: (): void => {},
  isSessionValid: (): void => {},
  idToken: '',
});

const useProvideAuth = (): ProviderProps => {
  const [user, setUser] = useState<User>({
    accountName: '',
    email: '',
  });
  const [idToken, setIdToken] = useState(() => {
    const token = window.localStorage.getItem('BrowserDomeSuperAdminToken');
    if (token) return token;
    return '';
  });

  const login = (email: string, password: string) =>
    new Promise((resolve, reject) => {
      AuthService.loginUser(email, password)
        .then((res: any) => {
          setUser((prevVal: User) => ({
            ...prevVal,
            email,
          }));
          setIdToken(res.idToken);
          window.localStorage.setItem(
            'BrowserDomeSuperAdminToken',
            res.idToken
          );
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  const register = (accountName: string) =>
    new Promise((resolve) => {
      setUser((prevVal: User) => ({
        ...prevVal,
        accountName,
      }));
      resolve(user);
    });

  const logout = () =>
    new Promise((resolve) => {
      AuthService.logoutUser().finally(() => {
        window.localStorage.removeItem('BrowserDomeSuperAdminToken');
        setUser({
          accountName: '',
          email: '',
        });
        resolve(true);
      });
    });

  const isSessionValid = () =>
    new Promise((resolve, reject) => {
      if (idToken.length === 0) reject();
      AuthService.getUserFromStorage()
        .then(async (res: any) => {
          const response: boolean = res.isValid;
          const userData: any = res.user;
          const { jwtToken } = userData?.idToken;
          setIdToken(jwtToken);
          await window.localStorage.setItem(
            'BrowserDomeSuperAdminToken',
            jwtToken
          );
          resolve({ response });
        })
        .catch((err) => reject(err));
    });

  return {
    user,
    login,
    register,
    logout,
    isSessionValid,
    idToken,
  } as ProviderProps;
};

export function ProvideAuth({ children }: ChildrenProps) {
  const auth: ProviderProps = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
export const useAuth = () => useContext(authContext);

ProvideAuth.propTypes = {
  children: PropTypes.element,
};

ProvideAuth.defaultProps = {
  children: () => {},
};
