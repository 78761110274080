import React, { useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Space, message } from 'antd';

import TenantsService from '../../services/tenants';

interface AddNewTenantProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AddNewTenant = ({ visible, onClose, onSuccess }: AddNewTenantProps) => {
  const [form] = Form.useForm();
  const [adding, setAdding] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setAdding(true);
    TenantsService.addNewTenant({
      name: values.name,
      account_name: values.account_name,
      admin_email: values.admin_email,
      contact_phone: values.contact_phone,
    })
      .then((result: any) => {
        if (result.status) {
          message.success('Tenant added succesfully!');
          form.resetFields();
          onSuccess();
        } else {
          message.error('Error adding new tenant. Please try again!');
        }
        setAdding(false);
      })
      .catch((error) => {
        const { data } = error.response;
        if (!data.status && data.message) {
          message.error(data.message);
        } else {
          message.error('There was a problem. Please try again!');
        }
        setAdding(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
      message.error('Please fill all required fields!');
    }
    setAdding(false);
  };

  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      title="Add new tenant"
      onCancel={() => {
        setAdding(false);
        onClose();
      }}
      footer={
        <Row justify="end">
          <Col>
            <Space>
              <Button onClick={onClose} disabled={adding}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={adding}
                loading={adding}
                onClick={() => {
                  form.submit();
                }}
              >
                Add
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <Form
        name="add-new-tenant"
        initialValues={{
          name: '',
          account_name: '',
          admin_email: '',
          contact_phone: '',
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input tenant descriptive name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Account Name"
          name="account_name"
          rules={[
            {
              required: true,
              message: 'Please input tenant account name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Admin email"
          name="admin_email"
          rules={[
            {
              required: true,
              message: 'Please input admin email!',
            },
            {
              type: 'email',
              message: 'Please input a valid email!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Contact phone"
          name="contact_phone"
          rules={[
            {
              required: false,
              message: 'Please input contact phone number!',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewTenant;
