import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Layout } from 'antd';
import { useAuth } from '../hooks/useAuth';
import LoginView from '../views/Login';
import Home from '../views/Home';
import Tenants from '../views/Tenants';
import AlertsView from '../views/Alerts';
import Sidebar from '../components/Sidebar';
import TenantsDetailsView from '../views/TenantDetails';

const { Header, Sider, Content } = Layout;

const MainRouter = () => {
  const auth = useAuth();
  const { isSessionValid } = auth;
  const [validSession, setValidSession] = useState<boolean>(false);
  const [loaded, setLoading] = useState<boolean>(false);
  const current = window.location;

  useEffect(() => {
    isSessionValid()
      .then(() => {
        setValidSession(() => true);
        setLoading(true);
      })
      .catch(() => {
        setValidSession(() => false);
        setLoading(true);
      });
  }, [auth, isSessionValid]);

  return (
    <Router>
      {!loaded ? (
        'Loading app...'
      ) : (
        <>
          {validSession ? <Redirect to={current} /> : <LoginView />}
          {validSession && (
            <Layout>
              <Sider className="sidebar-wrapper" theme="light">
                <Sidebar />
              </Sider>
              <Layout style={{ height: '100vh' }}>
                <Header className="bg-primary-shadow">
                  <h3 className="main-title">Super Admin Console</h3>
                </Header>
                <Content style={{ height: 'inherit', overflowY: 'auto' }}>
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/tenant" exact component={Tenants} />
                    <Route path="/tenant/:id/:region" exact>
                      <TenantsDetailsView />
                    </Route>
                    <Route path="/alerts" component={AlertsView} />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          )}
        </>
      )}
    </Router>
  );
};

export default MainRouter;
