import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Layout, Card, Image } from 'antd';
import { useAuth } from '../hooks/useAuth';
import Logo from '../assets/Logo_normal.svg';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function LoginView() {
  const { Header, Content } = Layout;
  const history = useHistory();
  const auth = useAuth();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [invalidError, setInvalidError] = useState<any>({});

  const attemptLogin = () => {
    setInvalidError(() => '');
    if (username && password && username.length && password.length) {
      if (!emailRegex.test(username.trim())) {
        return onFinishFailed('Please enter a valid email.');
      }
      setLoading(() => true);
      return auth
        .login(username, password)
        .then(() => {
          onFinish();
        })
        .catch((err) => onFinishFailed(err));
    }
    return onFinishFailed('Please complete all the fields correctly.');
  };
  const onFinish = () => {
    setLoading(() => false);
    history.push('/');
  };
  const onFinishFailed = (errorInfo: any) => {
    setLoading(() => false);
    setInvalidError(() => errorInfo);
  };

  return (
    <Layout>
      <Header>
        <h3 style={{ color: 'white' }}>Login to the SuperAdmin Dashboard</h3>
      </Header>
      <Content
        style={{
          padding: '4rem',
          minHeight: 'calc(100vh - 4rem)',
          textAlign: 'center',
        }}
      >
        <Card>
          <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 4 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Image src={Logo} />
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: 'Please input your username!' },
              ]}
            >
              <Input onChange={(e) => setUsername(e.target.value)} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password onChange={(e) => setPassword(e.target.value)} />
            </Form.Item>

            {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 10, span: 6 }}> */}
            {/*  <Checkbox>Remember me</Checkbox> */}
            {/* </Form.Item> */}
            <Form.Item wrapperCol={{ span: 24 }}>
              <span style={{ color: 'var(--bd-danger)' }}>
                {invalidError?.message || ''}
              </span>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => attemptLogin()}
                disabled={loading}
              >
                {loading ? 'Logging in...' : 'Login'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Content>
    </Layout>
  );
}
