import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Typography,
  Menu,
  Dropdown,
  Space,
  Divider,
} from 'antd';

import { DownOutlined } from '@ant-design/icons';
import TenantsService from '../../services/tenants';
import AllTenants from './AllTenants';
import AddNewTenant from './AddNewTenantModal';

const { Title } = Typography;

const REGIONS = new Map<string, string>([
  ['us-east-1', 'N. Virginia'],
  ['eu-central-1', 'Frankfurt'],
]);

const Tenants = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tenants, setTenants] = useState<any>({
    items: [],
    pages: 1,
    currentPage: 1,
    total: 0,
  });
  const [addNewTenantVisible, setAddNewTenantVisible] =
    useState<boolean>(false);
  const [region, setRegion] = useState<string>('us-east-1');

  const menu = (
    <Menu
      onClick={(info) => {
        setRegion(info.key);
        GetTenants(1, 10, info.key);
      }}
      items={Array.from(REGIONS, ([name, value]) => ({ name, value })).map(
        (item) => ({
          key: item.name,
          label: item.value,
        })
      )}
    />
  );

  const GetTenants = (page, pageSize, reg = region) => {
    setLoading(() => true);
    TenantsService.getAll(page, pageSize, reg)
      .then(({ data }: any) => {
        setLoading(() => false);
        setTenants(() => ({
          items: data.items,
          pages: data.pages,
          currentPage: data.current,
          total: data.total,
        }));
      })
      .catch(() => {
        setLoading(false);
        setTenants({
          items: [],
          pages: 1,
          currentPage: 1,
        });
      });
  };

  useEffect(() => {
    GetTenants(1, 10);
  }, []);

  return (
    <>
      <Row>
        <Title level={2}>Tenants</Title>
      </Row>
      <Row>
        <Col span={20}>
          <Button
            type="primary"
            onClick={() => {
              setAddNewTenantVisible(true);
            }}
          >
            Add new tenant
          </Button>
        </Col>
        <Col span={4}>
          <Space>
            Region
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button>
                {REGIONS.get(region)} <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AddNewTenant
            visible={addNewTenantVisible}
            onClose={() => {
              setAddNewTenantVisible(false);
            }}
            onSuccess={() => {
              setAddNewTenantVisible(false);
              GetTenants(1, 10);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider />
          <AllTenants
            items={tenants.items}
            total={tenants.total}
            currentPage={tenants.currentPage}
            pages={tenants.pages}
            loading={loading}
            onPageChange={(page, pageSize) => {
              GetTenants(page, pageSize);
            }}
            region={region}
          />
        </Col>
      </Row>
    </>
  );
};

export default Tenants;
