import React, { useState, useEffect } from 'react';
import { Row, Col, Menu, Dropdown, Button } from 'antd';
import TableSecondaryFilterComponent from './TableSecondaryFilter';
import { FilterType, FiltersTypes } from '../types/Admin';

export default function TableFilterComponent({
  filters,
  setFilter,
  loading,
}: FiltersTypes) {
  const [openedFilters, setOpenedFilters] = useState<FilterType[]>([]);
  const [filterObject, setFilterObj] = useState<any>({}); // use for Request Body

  const RemoveFilter = (filter, val) => {
    setOpenedFilters(openedFilters.filter((i) => i.key !== val.key));
    const r = { ...filterObject };
    r[filter.key] = undefined;
    setFilterObj(() => r);
    setFilter(r);
  };
  useEffect(() => {
    setFilter(filterObject);
  }, [filterObject]);

  const FiltersMenu = (
    <Menu
      items={
        filters && filters.length > 0
          ? filters.map((filterObj: FilterType, index: number) => ({
              key: `${index}-${Math.random()}`,
              label: (
                <Button
                  onClick={() => {
                    setOpenedFilters((prevVal) => {
                      const idx = openedFilters.includes(filterObj);
                      if (idx) {
                        return prevVal;
                      }
                      return [...prevVal, filterObj];
                    });
                  }}
                >
                  {filterObj.key}
                </Button>
              ),
            }))
          : []
      }
    />
  );

  return (
    <Row>
      <Col span={24}>
        <Dropdown overlay={FiltersMenu} trigger={['click']}>
          <Button onClick={(e) => e.preventDefault()}>Add Filter</Button>
        </Dropdown>
      </Col>
      {openedFilters.map((filter: FilterType, idx: number) => (
        <TableSecondaryFilterComponent
          key={`secondary-filter-${idx}`}
          filter={filter}
          loading={loading || false}
          removeFilter={(val) => RemoveFilter(filter, val)}
          setFilterObject={(valuesArray) => {
            setFilterObj((prev) => {
              const r = { ...prev };
              r[filter.key] = valuesArray.map((x) => x.id);
              return r;
            });
          }}
        />
      ))}
    </Row>
  );
}
