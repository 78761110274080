import React from 'react';
import { Row, Col, Card, Statistic } from 'antd';

type AnalyticsType = {
  active_agents: number;
  installed_agents: number;
  installed_endpoints: number;
  admin_users: number;
  admin_last_active: string;
  loading: boolean;
};

export default function TenantAnalytics({ ...props }: AnalyticsType) {
  const toDate = (dt) => new Date(dt).toDateString();
  return (
    <Card title="Analytics">
      <Row>
        <Col span={4}>
          <Statistic
            loading={props.loading}
            title="Active Agents"
            value={props.active_agents}
          />
        </Col>
        <Col span={4}>
          <Statistic
            loading={props.loading}
            title="Installed Agents"
            value={props.installed_agents}
          />
        </Col>
        <Col span={4}>
          <Statistic
            loading={props.loading}
            title="Installed Endpoints"
            value={props.installed_endpoints}
          />
        </Col>
        <Col span={4}>
          <Statistic
            loading={props.loading}
            title="Admin Users"
            value={props.admin_users}
          />
        </Col>
        <Col span={4}>
          <Statistic
            loading={props.loading}
            title="Last Active Admin User"
            value={
              props.admin_last_active && props.admin_last_active.length > 0
                ? toDate(props.admin_last_active)
                : 'N/A'
            }
          />
        </Col>
      </Row>
    </Card>
  );
}
